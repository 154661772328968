import React, { useCallback, useEffect, useRef, useState } from "react";
import { Router as AppRouter } from "./routes/Routes";
import "@pulse/shared-components/src/i18n/config";
import {
  FoundationTheme,
  LoadingIndicator,
  ThemeConfig,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  EMPTY_CHARACTER,
  GET_IN_TOUCH_LINK,
  PRIVACY_POLICY_LINK,
  RootContainer,
  SEND_EMAIL_LINK,
  SINGLE_SPACING,
  darkTheme,
  lightTheme,
} from "@pulse/shared-components";
import { useRootStore } from "./modules/root/store/RootStore";
import {
  getBrowserColorTheme,
  getDynamicTheme,
} from "@pulse/shared-components";
import {
  processSurveyLiveParams,
  useRouteNavigator,
} from "./routes/RoutesHelper";
import { Stack, Typography } from "@mui/material";
import "@pulse/shared-components/src/App.css";
import { navigateOrGetNetworkErrorPage } from "./utils/navigateOrGetNetworkErrorPage";
import { getEnv } from "mobx-state-tree";

export function App(): JSX.Element {
  const store = useRootStore();
  const navigateTo = useRouteNavigator();
  const [themes, setThemes] = useState<FoundationTheme[]>([
    lightTheme(),
    darkTheme(),
  ]);
  const [isThemeInitialised, setIsThemeInitialised] = useState(false);
  const feURL = getEnv(store).feURL;

  const StaticRootContent = (): React.ReactElement => {
    const typography = useTypography();
    const tokens = useFoundationColorTokens();
    const spacing = useSpacing();

    return (
      <RootContainer>
        <Stack
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
          padding={spacing.spaceLG}
        >
          <Stack maxWidth="760px">
            <Typography {...typography.b1} color={tokens.label}>
              This is a Business Application for Surveys and Market Research
              powered by Feedback Insights <br /> In case of any issues or
              concerns, please write to{SINGLE_SPACING}
              <Typography
                // This is required since by default Typography uses the <p> tag and <p> tag cannot be a descendant of another <p> tag.
                component="span"
                sx={{
                  ...typography.b1,
                  color: tokens.labelInfo,
                  display: "inline-block",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => window.open(SEND_EMAIL_LINK, "_blank")}
              >
                it@insightsfeedback.com
              </Typography>
            </Typography>
            <Typography {...typography.b1} color={tokens.label}></Typography>
            <Typography {...typography.b1} color={tokens.label}>
              <Typography
                // This is required since by default Typography uses the <p> tag and <p> tag cannot be a descendant of another <p> tag.
                component="span"
                sx={{
                  ...typography.b1,
                  color: tokens.labelInfo,
                  display: "inline-block",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => window.open(PRIVACY_POLICY_LINK, "_blank")}
              >
                Privacy Policy
              </Typography>
              {SINGLE_SPACING}|{SINGLE_SPACING}
              <Typography
                // This is required since by default Typography uses the <p> tag and <p> tag cannot be a descendant of another <p> tag.
                component="span"
                sx={{
                  ...typography.b1,
                  color: tokens.labelInfo,
                  display: "inline-block",
                  "&:hover": { cursor: "pointer" },
                }}
                onClick={() => window.open(GET_IN_TOUCH_LINK, "_blank")}
              >
                Contact Us
              </Typography>
            </Typography>
          </Stack>
        </Stack>
      </RootContainer>
    );
  };

  if (feURL === window.location.href) {
    return (
      <Stack className={getBrowserColorTheme()}>
        <ThemeConfig themes={themes} currentTheme={getBrowserColorTheme()}>
          <StaticRootContent />
        </ThemeConfig>
      </Stack>
    );
  }

  const fetchThemes = async (
    surveyLinkId: string,
    isPreviewURL: boolean,
  ): Promise<void> => {
    await store.initialiseSurvey(surveyLinkId, isPreviewURL);
    const lightTokens = store.surveyStore.lightThemeTokens;
    const darkTokens = store.surveyStore.darkThemeTokens;
    if (lightTokens && darkTokens && store.surveyStore.surveySettings) {
      setThemes(
        getDynamicTheme(
          [
            lightTokens.primary100Code,
            lightTokens.primary200Code,
            lightTokens.primary300Code,
            lightTokens.primary400Code,
            lightTokens.primary500Code,
          ],
          [
            darkTokens.primary100Code,
            darkTokens.primary200Code,
            darkTokens.primary300Code,
            darkTokens.primary400Code,
            darkTokens.primary500Code,
          ],
          store.surveyStore.surveySettings.font,
        ),
      );
    } else {
      navigateOrGetNetworkErrorPage(
        store.networkingStore.errorStore.error,
        navigateTo,
      );
      console.error(
        "LightTokens, DarkTokens and surveySettings must not be null in this flow.",
      );
      if (store.networkingStore.errorStore.error === null) {
        navigateTo.internalServerError();
      }
    }
  };
  const getThemes = useCallback(async () => {
    await processSurveyLiveParams(
      async (surveyLinkId, isPreviewURL) => {
        await fetchThemes(surveyLinkId, isPreviewURL);
      },
      navigateTo,
      store.surveyStore.generateGenericSurveyLink,
    );
    setIsThemeInitialised(true);
  }, []);

  // Below regex check is added to run the useEffect only once in strict mode when we are running the app locally.
  // fetchThemes fetches the current settings as well. So, if we don't show the welcome page or take content of user,
  // then we are updating the survey link status to started.
  // In strict mode, the use effect will run twice and make that API call twice as well, which will throw 500 because of concurrent updates in DB.
  // The else block will run in prod.
  const localhostRegex = /localhost/;
  if (localhostRegex.test(store.baseURL ?? EMPTY_CHARACTER)) {
    const isThemeFetched = useRef(false);
    useEffect(() => {
      if (!isThemeFetched.current) {
        getThemes();
      }
      return () => {
        isThemeFetched.current = true;
      };
    }, []);
  } else {
    useEffect(() => {
      getThemes();
    }, []);
  }

  return (
    <Stack className={getBrowserColorTheme()}>
      <ThemeConfig themes={themes} currentTheme={getBrowserColorTheme()}>
        {isThemeInitialised ? (
          <AppRouter />
        ) : (
          <LoadingIndicator isLoading={true} />
        )}
      </ThemeConfig>
    </Stack>
  );
}
